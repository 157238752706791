<template>
    <div>
        <el-divider></el-divider>
        <el-col :span="11">
            <el-input placeholder="请输入序列号" v-model="searchObj.onlyText" @change="getList(1)"><el-button slot="append"
                    icon="el-icon-search" @click="getList(1)"></el-button></el-input>
            <vxe-table ref="table1" :data="tableData" border @current-change="currentChange" :row-config="rowConfig"
                :loading="loading" style="margin-top:10px;">
                <vxe-column type="expand" width="40">
                    <template #content="{ row }">
                        试验环境温度：{{ row.shiYanHuangJingWenDu / 10.0 }}℃<br />
                        试验轮接触强度：{{ row.shiYanLunJieChuQiangDu / 100.0 }}Mpa<br />
                        试件密度：{{ row.shiJianMiDu }}kg/m³<br />
                        空隙率：{{ row.kongXiLv / 100.0 }}%<br />
                        试件稳定度：{{ row.shiYanWenDingDu / 10.0 }}次/mm<br />
                        碾压次数：{{ row.yunXingCiShu }}次<br />
                        相对位移值1：{{ row.xiangDuiWeiYi1 / 1000.0 }}mm<br />
                        相对位移值2：{{ row.xiangDuiWeiYi2 / 1000.0 }}mm
                    </template>
                </vxe-column>
                <vxe-column field="serialNumber" title="序列号"></vxe-column>
                <vxe-column field="shiYanShiJian" title="试验时间" width="150"></vxe-column>
                <vxe-column field="shiYanBianHao" title="试验编号" align="right"></vxe-column>
                <vxe-column field="yunXingMiaoShu" title="运行时长(s)" align="right"></vxe-column>
                <vxe-column field="gongZuoFangShi" title="工作方式"
                    :formatter="({ cellValue }) => { return cellValue == '0' ? '非浸水' : '浸水' }"></vxe-column>
                <vxe-column field="shiYanXiShu" title="试验系数"
                    :formatter="({ cellValue }) => { return cellValue == '1' ? '路面0.8' : '实验室1.0' }"></vxe-column>
            </vxe-table>
            <vxe-pager align="left" @page-change="getList" :page-size.sync="searchObj.pageSize"
                :current-page.sync="searchObj.currentPage" :total="searchObj.total"
                :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
            </vxe-pager></el-col>
        <el-col :span="12" :offset="1">
            <el-tabs v-model="activeName">
                <el-tab-pane label="图形" name="first">
                    <div id="CZY006_Chart"></div>
                </el-tab-pane>
                <el-tab-pane label="明细" name="second">
                    <vxe-toolbar>
                        <template #buttons>
                            <vxe-button @click="() => { $refs.table2.exportData({ type: 'csv' }); }" status="primary"
                                icon="vxe-icon-arrow-down">导出</vxe-button>
                        </template>
                    </vxe-toolbar>
                    <vxe-table ref="table2" :data="tableDataDetail" :max-height="ulHeight" border>
                        <vxe-column field="fenZhong" align="right" title="时间(min)"></vxe-column>
                        <vxe-column field="fenZhongWeiYi" align="right" title="位移(mm)"></vxe-column>
                    </vxe-table>
                </el-tab-pane>
            </el-tabs>
        </el-col>
    </div>
</template>
<script>
import Vue from "vue";
import Vant from "vant";

//chart相关引用
import HighCharts from "highcharts";
import dark from "highcharts/themes/dark-unica";
import exportHighChart from "highcharts/modules/exporting";
import exportOffHighChart from "highcharts/modules/offline-exporting";
import HighchartsHanHua from "@/util/highcharts-lang-zh_CN.js";
exportHighChart(HighCharts);
exportOffHighChart(HighCharts);
dark(HighCharts);
HighchartsHanHua.HighchartsHanHua(HighCharts);
HighCharts.setOptions({ global: { useUTC: false } });

Vue.use(Vant);
export default {
    data() {
        return {
            ulHeight: 500,
            activeName: "first",
            tableData: [],
            loading: false,
            rowConfig: { isCurrent: true, isHover: true },
            searchObj: {
                onlyText: "",
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            mapData: [{ name: "位移/时间", data: [] }],
            tableDataDetail: []
        };
    },

    methods: {
        getList(v) {
            let that = this;
            that.loading = true;
            if (v) {
                that.currentPage = 1;
            }
            that.axios.post("CZY006_Result/GetList", that.searchObj).then((response) => {
                that.loading = false;
                that.searchObj.total = response.data.data.total;
                that.tableData = response.data.data.data;
                if (that.tableData.length > 0) {
                    that.$refs.table1.setCurrentRow(that.tableData[0]);
                    that.currentChange({ newValue: that.tableData[0] });
                }
            })
        },
        currentChange({ newValue }) {
            let that = this;
            that.mapData[0].data = []
            let arrs = newValue.arrayWeiYi.split(",");
            that.tableDataDetail = [];
            for (let i = 0; i < arrs.length - 1; i++) {
                that.mapData[0].data.push([i, arrs[i] / 1000.0]);
                that.tableDataDetail.push({ "fenZhong": i, "fenZhongWeiYi": arrs[i] / 1000.0 })
            }
            that.loadCharts();

        },
        loadCharts() {
            let that = this;
            HighCharts.chart("CZY006_Chart", {
                lang: {
                    viewFullscreen: "全屏",
                    printChart: "打印图表",
                    downloadJPEG: "导出 JPEG",
                    downloadPDF: "导出 PDF",
                    downloadPNG: "导出 PNG",
                    downloadSVG: "导出 SVG",
                },
                tooltip: {
                    formatter: function () {
                        return this.series.name + "<br />位移：" + this.y + "mm<br />时间：" + this.x + "min";
                    },
                },
                plotOptions: { series: { turboThreshold: 0 } }, //大于一千个点要用这个。否则不显示。
                title: { text: "位移曲线图" },
                credits: { enabled: false },
                //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
                yAxis: { title: { text: "位移(mm)" } },
                series: that.mapData,
            });
        },
    },
    mounted() {
        let that = this;
        this.ulHeight = this.$parent.$el.offsetHeight - 200;
        that.getList();
    },
};
</script>
  
<style scoped></style>